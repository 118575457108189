import {useSiteMetadata} from "../hooks";
import Layout from "../components/Layout";
import React from "react";
import TextPolicyPage from "../components/TextPolicyPage";
import {COOKIES_POLICY} from "../constants/cookies-policy";
import {graphql, useStaticQuery} from "gatsby";

const CookiesPolicyTemplate = ({location}) => {
    const {subtitle: siteSubtitle} = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
      query {
          bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "cookies" } }) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);

    return (
        <Layout title="Cookies Policy"
                darkBreadcrumbs
                description={siteSubtitle}
                location={location}
                socialImage={bg.nodes[0].publicURL}>
            <TextPolicyPage page={COOKIES_POLICY}/>
        </Layout>
    );
};

export default CookiesPolicyTemplate;

