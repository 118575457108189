export const COOKIES_POLICY = {
    HEADER: 'Cookies policy',
    SUBHEADER: 'Cybervision Inc. (“Cybervision”, “Us”, “Our”, or “We”) has created this Cookies Policy describing what cookies are, how We use cookies, how to modify Your browser preferences to disable cookies, and what cookies We use. ',
    MAIN_TEXT: ['This Cookies Policy is effective to all visitors and users of Cybervision websites (“Sites”). Please read this Cookies Policy in conjunction with Our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, which sets out additional details on how We use personally identifiable information and Your various rights.'],
    CONTENT: {
        cookiesDefinition: {
            heading: 'What Cookies Are',
            text: [
                'An "Internet cookie" (the term known as a "browser cookie", or an "HTTP cookie", or simply a "cookie") is a small file consisting of letters and numbers that is stored on Your computer, handheld device, or other equipment that You use to access the Internet.',
                'The cookie is installed by request issued by a server to a web browser (e.g. Internet Explorer, Firefox, Chrome) and is completely "passive" (does not contain software viruses or spyware and cannot access the information on the user’s devices). Cookies store information in a small text file that allows various website functionality to work correctly. The web browser will recognize cookies until they expire or are deleted.',
                'In what is known as “Web 2.0”, "cookies" play an important role in facilitating access and delivery of multiple services over the Internet, such as customizing the version of the Sites based on the user’s current device type. Cookies offer valuable feedback on how websites are used so that they could be made more efficient and accessible to users. Cookies also enable multimedia and other applications on other websites to be included into a particular website to create a richer browsing experience.',
            ]
        },
        useOfCookies: {
            heading: 'How We Use Cookies',
            text: [
                'Our Sites, like many others, store and retrieve information in Your browser by using cookies. This information is used to make the Sites work as You expect it to. Cookies can be used to give You a more personalized web experience.',
                'If You want to learn more about the general uses of cookies, You may visit <a href="http://cookiepedia.co.uk/all-about-cookies" target="_blank">Cookiepedia</a>.'
            ]
        },
        disableCookies: {
            heading: 'How to Modify Your Browser Preferences to Disable Cookies',
            text: [
                'Most web browsers allow control of most cookies through the browser settings. For more information on how to delete and control cookies stored on Your device, You may visit: <a href="https://www.aboutcookies.org/" target="_blank">www.aboutcookies.org</a> or <a href="https://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a>.',
                {
                    styles: ['bold'],
                    text: 'Please note, however, that if You delete cookies or refuse to accept them, You might not be able to use some or all of the features We offer. You may not be able to log in, store Your preferences, and some of Our Sites’ pages might not display properly.',
                },
                'Find out how to manage cookies in popular browsers:',
                [
                    'Apple Safari: <a href="https://support.apple.com/en-us/HT201265" target="_blank">Safari on your iPhone, iPad, iPod touch</a> or <a href="https://support.apple.com/et-ee/guide/safari/sfri11471/mac" target="_blank">Safari on Mac</a>',
                    '<a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Google Chrome</a>',
                    '<a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank">Microsoft Edge</a>',
                    '<a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Microsoft Internet Explorer</a>',
                    '<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</a>',
                    '<a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/" target="_blank">Opera</a>',
                ],
                'To find information relating to other browsers, visit the browser’s developer website.'
            ],
        },
        cookiesWeUse: {
            heading: {
                text: 'What Cookies We Use',
                style: {
                    marginBottom: '4px',
                },
            },
            text: [
                {
                    styles: ['bold', 'centered'],
                    style: {
                        marginBottom: '48px'
                    },
                    mediaStyle: {
                        'margin-bottom': '24px',
                        'margin-top': '12px',
                    },
                    text: 'Below is an up to date list of the cookies We use on Our Sites.'
                },
                {
                    styles: ['subheading'],
                    text: 'Strictly necessary cookies',
                    categoryCookies: 'functional'
                },
                {
                    table: true,
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            ['__hs_opt_out', 'This cookie is used to remember not to ask the visitor to accept cookies again.', '13 months'],
                            ['hs-messages-hide-welcome-message', 'This cookie is used to prevent the welcome message from appearing again for one day after it is dismissed.', '1 day'],
                            ['XSRF-TOKEN', 'Security', '1 hour'],
                            ['cybervisiontech_session', 'Handles session for logged in users.', '1 hour'],
                            ['‹Auto generated token›', 'Handles session for logged in users.', '1 hour'],
                            ['connect.sid', 'Identifies the user and allows authentication to the server.', 'Session'],
                            ['io', 'Distributes traffic to the website on several WebSocket servers in order to optimize response times.', 'Session'],
                            ['AllowedConsent', 'Stores the user`s cookie consent state.', '1 year'],
                            ['KEYCLOAK_IDENTITY', 'Security: user authentication', 'Session'],
                            ['AUTH_SESSION_ID', 'Security: user authentication', 'Session'],
                            ['KC_RESTART', 'Security: user authentication', 'Session'],
                            ['Oauth_token_request_state', 'Security: user authentication', 'Session'],
                            [
                                '__cf_bm',
                                'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                                '2 years',
                            ],
                            [
                                '_GRECAPTCHA',
                                'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                                '179 days'
                            ],
                            [
                                'rc::a',
                                'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                                'Persistent'
                            ],
                            [
                                'rc::b',
                                'This cookie is used to distinguish between humans and bots.',
                                'Session'
                            ],
                            [
                                'rc::c',
                                'This cookie is used to distinguish between humans and bots.',
                                'Session'
                            ]

                            // ['__unam', 'This cookie counts clicks and shares of a page.', '9 months']
                        ]
                    }
                },
                'These cookies are necessary for the Sites to function and cannot be switched off in Our systems. They are usually only set in response to actions made by You that amount to a request for services, such as setting Your preferences, logging in, or filling out forms.',
                'You can set Your browser to block or alert You about these cookies, however, in this case some parts of the Sites will not work.',
                {
                    styles: ['subheading'],
                    text: 'Preferences',
                    categoryCookies: 'preferences',
                    warningText: 'By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.'
                },
                {
                    table: true,
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            ['isBannerShow', 'Stores the state of the announcement banner.', '2 days'],
                            ['TOGGLE_ADMIN_SIDEBAR', 'Stores the state of the sidebar displayed in the website.', '1 year'],
                            ['TOGGLE_SOLUTION_SIDEBAR', 'Stores the state of the sidebar displayed in the website.', '1 year'],
                            ['redirect', 'Stores url after logout', 'Session'],
                            ['@@scroll#', 'Unclassified', 'Session'],
                            ['keyval-store#keyval', 'Used to maintain visitors` preferences throughout the visit and sub-pages.', 'Session'],
                            [
                                'isCookieBannerClosed',
                                'Unclassified',
                                'Persistent',
                            ],
                            
                            // ['__hs_opt_out', 'Is used by the ​opt-in privacy policy​ to remember not to ask the visitor to accept cookies again.', '13 months'],
                        ]
                    }
                },
                {
                    styles: ['subheading'],
                    text: 'Statistics',
                    categoryCookies: 'statistics',
                    warningText: 'By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.'
                },
                'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
                {
                    table: true,
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_ga" target="_blank">_ga</a>',
                                'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                                '2 years'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gid" target="_blank">_gid</a>',
                                'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                                '1 day'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gat_UA-47512871-10" target="_blank">_gat_UA-47512871-10</a>',
                                'Performance',
                                '1 years'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gat_UA-47512871-2" target="_blank">_gat_UA-47512871-2</a>',
                                'Performance',
                                '1 years'
                            ],
                            [
                                '_ga_KBN5VJ1LHK',
                                'This cookie is stored by Google Analytics and is used to store information on how visitors use a website and helps create analytics reports on website usage.',
                                '2 years',
                            ],
                            [
                                'collect',
                                'Used to send data to Google Analytics about the visitor`s device and behavior. Tracks the visitor across devices and marketing channels.',
                                'Session'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_pk_id" target="_blank">_pk_id</a>',
                                'Used to store a few details about the user such as the unique visitor ID.',
                                '13 months'
                            ],
                            [
                                '_pk_ref',
                                'Used to store the attribution information, the referrer initially used to visit the website.',
                                '6 months'
                            ],
                            [
                                '_pk_ses, _pk_cvar, _pk_hsr',
                                'Short lived cookies used to temporarily store data for the visit.',
                                '30 minutes'
                            ],
                            [
                                '_pk_testcookie',
                                'Used to check whether the visitor’s browser supports cookies.',
                                'Session'
                            ],
                            // [
                            //     'hubspotutk',
                            //     'This cookie keeps track ofa visitor\'s identity. It is passed to HubSpot on form submission and used when deduplicating contacts.',
                            //     '13 months'
                            // ],
                            // [
                            //     '__hstc',
                            //     'The main cookie for tracking visitors.',
                            //     '13 months'
                            // ],
                            // [
                            //     '__hssc',
                            //     'Is used to determine ifHubSpot should increment the session number and timestamps in the __hstc cookie.',
                            //     '30 minutes'
                            // ],
                            // [
                            //     '__hssrc',
                            //     'Is also set to determine if the visitor has restarted their browser.',
                            //     'session',
                            //     'session'
                            // ],
                            // [
                            //     '<a target="_blank" href="https://cookiepedia.co.uk/cookies/__cfduid">__cfduid</a>',
                            //     'Is established by the CloudFlare service so that web traffic to the HubSpot platform is redirected through CloudFlare. It does not correspond to any user ID in the web app or store any identifiable personal information.',
                            //     '1 month'
                            // ]
                        ]
                    }
                },
                {
                    styles: ['subheading'],
                    text: 'Third-Party Cookies',
                    categoryCookies: 'thirdParty',
                    warningText: 'By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.'
                },
                {
                    table: true,
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            [
                                '__cfduid',
                                'Security',
                                '1 year'
                            ]
                            // [
                            //     'DSID',
                            //     [
                            //         'These cookies are set by Google DoubleClick and are used for serving targeted advertisements that are relevant to the visitor across the web. Targeted advertisements may be displayed to the visitor based on previous visits to this website. For example, advertisements about a topic you have expressed an interest in while browsing our site may be displayed to you across the web. In addition, these cookies measure the conversion rate of ads presented to the visitor.',
                            //         'For more information, please visit <a href="https://google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>.',
                            //         'If you wish to disable DoubleClick cookies on your browser please visit <a href="https://adssettings.google.com" target="_blank">adssettings.google.com</a>.',
                            //     ],
                            //     '14 days'
                            // ],
                            // [
                            //     'IDE',
                            //     [
                            //         'These cookies are set by Google DoubleClick and are used for serving targeted advertisements that are relevant to the visitor across the web. Targeted advertisements may be displayed to the visitor based on previous visits to this website. For example, advertisements about a topic you have expressed an interest in while browsing our site may be displayed to you across the web. In addition, these cookies measure the conversion rate of ads presented to the visitor.',
                            //         'For more information, please visit <a href="https://google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>.',
                            //         'If you wish to disable DoubleClick cookies on your browser please visit <a href="https://adssettings.google.com" target="_blank">adssettings.google.com</a>.'
                            //     ],
                            //     '23 months'
                            // ],
                            // [
                            //     '_fbp',
                            //     'These cookies are set by <a href="https://cookiedatabase.org/service/facebook/" target="_blank">Facebook</a> to <a href="https://cookiedatabase.org/cookiefunctions/track-visits-across-websites/" target="_blank">store and track visits across websites</a>.',
                            //     '3 months'
                            // ]
                        ]
                    }
                },
                'These cookies are used to identify malicious visitors on Our Sites in order to reduce the chance of blocking legitimate users.',
                {
                    styles: ['bold', 'centered'],
                    text: 'Google Analytics Cookies'
                },
                {
                    table: true,
                    style: {
                        marginBottom: '24px'
                    },
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_ga" target="_blank">_ga</a>',
                                'Performance',
                                '2 years'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gat_UA-47512871-10" target="_blank">_gat_UA-47512871-10</a>',
                                'Performance',
                                '1 year'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gat_UA-47512871-2" target="_blank">_gat_UA-47512871-2</a>',
                                'Performance',
                                '1 years'
                            ],
                            [
                                '<a href="https://cookiepedia.co.uk/cookies/_gid" target="_blank">_gid</a>',
                                'Performance',
                                '1 year'
                            ],
                            [
                                '_ga_KBN5VJ1LHK',
                                'This cookie is stored by Google Analytics and is used to store information on how visitors use a website and helps create analytics reports on website usage.',
                                '2 years'
                            ],
                            [
                                '_gaexp',
                                'Used to determine a user`s inclusion in an experiment and the expiry of experiments a user has been included in.',
                                'Depends on the length of the experiment, but typically 90 days'
                            ],
                            [
                                '_gaexp_rc',
                                'This cookie is set by Google Analytics and is used to determine a user`s inclusion in an experiment and the expiry of experiments that a user has been included in.',
                                '10 seconds'
                            ],
                            [
                                '_opt_awcid',
                                'Used for campaigns mapped to Google Ads Customer IDs.',
                                '24 hours'
                            ],
                            [
                                '_opt_awmid',
                                'Used for campaigns mapped to Google Ads Campaign IDs',
                                '24 hours'
                            ],
                            [
                                '_opt_awgid',
                                'Used for campaigns mapped to Google Ads Campaign IDs',
                                '24 hours'
                            ],
                            [
                                '_opt_awkid',
                                'Used for campaigns mapped to Google Ads Campaign IDs',
                                '24 hours'
                            ],
                            [
                                '_opt_utmc',
                                'Stores the last utm_campaign query parameter',
                                '24 hours'
                            ],
                            [
                                '_opt_expid',
                                'This cookie is created when running a redirect experiment. It stores the experiment ID, the variant ID and the referrer to the page that`s being redirected',
                                '10 seconds'
                            ]
                            // [
                            //     '<a href="https://cookiepedia.co.uk/cookies/_gat" target="_blank">_gat</a>',
                            //     'Performance',
                            //     '10 minutes'
                            // ]
                        ]
                    }
                },
                'These Google Analytics cookies allow Us to count visits and traffic sources so that We could measure and improve the performance of Our Sites. They help Us identify which pages are the most and least popular and see how visitors move around the Sites.',
                'All information collected by these cookies is aggregated and, therefore, anonymous. If You do not allow these cookies, We will not be able to monitor its usage metrics in order to enhance Your experience.',
                'To opt out of being tracked by Google Analytics across all websites, You may visit <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout</a>.',
                // {
                //     styles: ['bold', 'centered'],
                //     text: 'Necessary'
                // },
                // {
                //     table: true,
                //     style: {
                //         marginBottom: '24px'
                //     },
                //     content: {
                //         head: ['Cookie name', 'Purpose', 'Expiration'],
                //         body: [
                //             [
                //                 '__cf_bm',
                //                 'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                //                 '2 years',
                //             ],
                //             [
                //                 '_GRECAPTCHA',
                //                 'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                //                 '179 days'
                //             ],
                //             [
                //                 'rc::a',
                //                 'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
                //                 'Persistent'
                //             ],
                //             [
                //                 'rc::b',
                //                 'This cookie is used to distinguish between humans and bots.',
                //                 'Session'
                //             ],
                //             [
                //                 'rc::c',
                //                 'This cookie is used to distinguish between humans and bots.',
                //                 'Session'
                //             ]
                //         ]
                //     }
                // },
                // 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                {
                    styles: ['bold', 'centered'],
                    text: 'Marketing'
                },
                {
                    table: true,
                    style: {
                        marginBottom: '24px'
                    },
                    content: {
                        head: ['Cookie name', 'Purpose', 'Expiration'],
                        body: [
                            [
                                '__ptq.gif',
                                'Sends data to the marketing platform Hubspot about the visitor`s device and behaviour. Tracks the visitor across devices and marketing channels.',
                                'Session',
                            ],
                        ]
                    }
                },
                'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                // {
                //     styles: ['bold', 'centered'],
                //     text: 'Unclassified'
                // },
                // {
                //     table: true,
                //     style: {
                //         marginBottom: '24px'
                //     },
                //     content: {
                //         head: ['Cookie name', 'Purpose', 'Expiration'],
                //         body: [
                //             [
                //                 'isCookieBannerClosed',
                //                 'Unclassified',
                //                 'Persistent',
                //             ],
                //         ]
                //     }
                // },
                // 'Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.',
            ]
        }
    }
}
